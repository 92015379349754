import React from "react"
import "../styles/privacy.scss"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import SEO from "../components/seo"
import JobForm2 from "../components/job-application-form2"

const DataScientistApprentice = () => (
  <Layout>
    <ScrollArrow showBelow={450} />

    <SEO title="Automation DevOps Engineer" />
    <div className="job-vac-section">
      <div className="eng-chal-content">
        <div className="transparent">
          <div className="outer-container">
            <div className="inner-container">
              <div className="column">
                <h1>Level 6 Data Scientist Apprenticeship</h1>
                <h2>Job Vacancy Information and Application Form</h2>
                <h3>Contact us and send us your CV for review.</h3>
                {/* <ButtonB href="/about">Our Story</ButtonB> */}
              </div>
            </div>

            <div className="outer-container">
              <div className="inner-container" style={{ textAlign: "left" }}>
                <div style={{ width: "100%" }}>
                  <h3>Title</h3>
                  <div className="aut-div">
                    <p>Level 6 Data Scientist Apprenticeship</p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Location</h3>
                  <div className="aut-div">
                    <p>
                      Home based
                    </p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Role Type:</h3>
                  <div className="aut-div">
                    <p>
                      Apprenticeship
                    </p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Package</h3>
                  <div className="aut-div">
                    <p>Competitive Salary + Benefits </p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Role Type</h3>
                  <div className="aut-div">
                    <p>Full Time - Permanent</p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Role ID</h3>
                  <div className="aut-div">
                    <p>VTAPPR25</p>
                  </div>
                </div>

                <div>
                  <h3>Role Summary</h3>
                  <div className="aut-div">
                    <p>
                      We are looking for a Data Scientist Apprentice to join our world-class team.
                      This role is designed to help you build the fundamental skills needed to succeed in automation, security, and infrastructure, setting you up for a long-term career in technology.
                      You will be hands-on with data, learning from experienced mentors, and working on real-world projects that have a tangible impact.{" "}
                    </p>
                  </div>
                </div>

                <div style={{ textAlign: "left", width: "100%" }}>
                  <h3>What You’ll Be Doing:</h3>
                  <div className="aut-div">
                    <ul>
                      <li>
                        • Learning and applying data science techniques, including data collection, cleaning, and analysis.
                      </li>
                      <li>
                        • Developing skills in automation, scripting, and AI-driven data insights.
                      </li>
                      <li>
                        •	Gaining exposure to cybersecurity principles, helping ensure data integrity and security.
                      </li>
                      <li>
                        •	Assisting in infrastructure projects, including cloud computing, DevOps, and networking.{" "}
                      </li>
                      <li>
                        •	Working with a highly skilled team, absorbing knowledge from industry-leading professionals.
                      </li>
                      <li>
                        •	Collaborating on real-world projects that contribute to our company’s success.
                      </li>
                      <li>
                        •	Engaging in our vibrant team culture—expect hackathons, problem-solving challenges, and lots of geeky fun!
                      </li>
                    </ul>
                  </div>
                </div>

                <div style={{ textAlign: "left", width: "100%" }}>
                  <div>
                    <br />
                    <h3>What We’re Looking For:</h3>
                    <div className="aut-div">
                      <ul>
                        <li>
                          •	Enthusiasm for technology and a willingness to learn.
                        </li>
                        <li>
                          •	An analytical mindset with problem-solving skills.
                        </li>
                        <li>
                          •	Some exposure to Python, APIs SQL, or another programming language (preferred but not required).
                        </li>
                        <li>
                          •	A keen interest in data science, machine learning, and AI.
                        </li>

                        <li>
                          •	Understanding of (or willingness to learn) security and automation principles.
                        </li>
                        <li>
                          •	Strong communication skills and the ability to work collaboratively in a team.
                        </li>

                        <li>
                          •	A desire to grow into a technical engineer and consultant role over time.
                        </li>

                      </ul>
                    </div>
                  </div>


                  <div>
                    <br />
                    <h3>What You’ll Gain:</h3>
                    <div className="aut-div">
                      <ul>
                        <li>
                          •	A structured 42-month apprenticeship with hands-on experience.
                        </li>
                        <li>
                          •	Training & mentorship from leading professionals in the field.
                        </li>
                        <li>
                          •	Opportunities to work with cutting-edge automation, security, and AI-driven tools.
                        </li>
                        <li>
                          •	Exposure to a wide range of technical disciplines, preparing you for a successful career.
                        </li>
                        <li>
                          • The chance to be part of an amazing team that values learning, innovation, and fun!
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <br />
                    <h3>Apprenticeship details:</h3>
                    <div className="aut-div">
                      <div style={{ width: "100%" }}>
                        <h3>Title</h3>
                        <div className="aut-div">
                          <p>Level 6 Data Scientist Apprenticeship</p>
                        </div>
                      </div>
                    </div>

                    <div className="aut-div">
                      <div style={{ width: "100%" }}>
                        <h3>Course provider</h3>
                        <div className="aut-div">
                          <p>University of Nottingham</p>
                        </div>
                      </div>
                    </div>

                    <div className="aut-div">
                      <div style={{ width: "100%" }}>
                        <h3>Provider link</h3>
                        <div className="aut-div">
                          <a href="https://www.nottingham.ac.uk/mathematics/business/data-scientist-degree-apprenticeship.aspx" target="_blank" rel="noreferrer">
                            Data Scientist Degree Apprenticeship - The University of Nottingham</a>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div>
                    <br />
                    <h3>Academic requirements:</h3>
                    <div className="aut-div">
                      <strong>You must have obtained or be predicted:</strong>
                      <ul>
                        <li>
                          •	BBC at A-Level to include Maths. Please note that we cannot accept A-Levels Citizenship Skills, General Studies, and Critical Thinking.
                        </li>
                        <li>
                          •	o	You must also have obtained GCSE's at A*- C, 5 - 9 in Maths and English Language
                        </li>
                      </ul>
                    </div>
                  </div>


                  <div>
                    <br />
                    <h3>Additional requirements:</h3>
                    <div className="aut-div">
                      <ul>
                        <li>
                          •	You must be able to travel to the training provider for the academic elements of the Level 6 Apprenticeship.
                          Expenses for travel will be reimbursed in line with our expenses policy.
                        </li>
                        <li>
                          •	Have an interest and/or experience within technology and cyber security,.
                          Python experience is beneficial but not essential.
                        </li>
                        <li>
                          •	Leadership skills OR experience in leadership, this can be via hobbies or education.
                        </li>
                        <li>
                          •	Any extracurricular activities & or hobbies.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <br />
                    <h3>How to apply:</h3>
                    <div className="aut-div">
                      <ul>
                        <li>
                          •	Please fill in the application and include both a CV and a covering letter.
                        </li>
                      </ul>
                    </div>
                  </div>


                  <div>
                    <br />
                    <h3>Benefits:</h3>
                    <div className="aut-div">
                      <ul>
                        <li>
                          •	Home based working
                        </li>
                        <li>
                          •	On demand learning, access to courses, modules, and lectures via multiple digital learning platforms
                        </li>
                        <li>
                          •	Coaching and Mentoring
                        </li>
                        <li>
                          •	25 days annual holiday excluding bank holidays
                        </li>
                        <li>
                          •	Pension scheme, with life assurance and health insurance
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <br />
                    <h3>Benefits:</h3>
                    <div className="aut-div">
                      <p>
                        If you are passionate about technology and eager to kickstart your career as a Data Scientist Apprentice,
                        we’d love to hear from you! Apply now by sending your CV and a covering letter to jobs@virtigon.com
                        and a brief cover letter explaining why you’d be a great fit for Virtigon.
                      </p>
                    </div>
                  </div>
                  <div>
                    <br />
                    <h3>Recruitment Process:</h3>
                    <div className="aut-div">
                      <p>
                        At Virtigon, we’re committed to creating an inclusive and supportive recruitment experience where every candidate feels respected, heard, and empowered to succeed.
                      </p>
                      <p>
                        If you have a disability or health condition that may affect how you engage with our assessment process, please let your recruiter know — we’re happy to make reasonable adjustments to support you.
                      </p>
                      <p>
                        We’re building a culture that celebrates diversity, values integrity and merit, and helps people reach their full potential.
                      </p>
                      <p>
                        No matter what your background, you’re welcome here. Join us and be part of something meaningful.
                      </p>
                      <p>
                        After you submit your CV and covering letter, your application will be carefully reviewed. If you are shortlisted, you will be invited to attend an initial video interview, followed by an in-person meeting for a more in-depth conversation over coffee or tea.
                      </p>
                    </div>
                  </div>


                  <div>
                    <div style={{ textAlign: "left" }}>
                      <br />
                      <h3>About Virtigon</h3>
                      <div
                        className="aut-div"
                      >
                        <p>
                          At Virtigon, we are a team of passionate,
                          highly experienced tech enthusiasts who thrive on pushing the boundaries of cutting-edge technology.
                          Our work combines expertise in automation, security, and infrastructure,
                          and we take great pride in our collaborative, fun, and dynamic culture.
                          If you are enthusiastic about technology and eager to develop a strong foundation in data science,
                          this apprenticeship will give you the perfect launchpad into a career as a technical engineer and,
                          ultimately, a consultant.{" "}
                        </p>
                      </div>
                      <h3>Apply Now:</h3>
                      <JobForm2 />
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Layout >
)

export default DataScientistApprentice
